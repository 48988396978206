<template>
    <a-modal
    width="530px"
    class="validation-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :visible="true"
    :closable="true"
    @cancel="onExit">
    <h1 class="flex align-center txt-30 txt-bold txt-font-din-medium">
      {{ $t('components.titles.validateProtocol') }}
    </h1>
    <p class="txt-16">
      {{ $t('components.description.howProtocolValidated') }}
    </p>
    <a-select
      v-model="reason"
      class="w100"
      :get-popup-container="(triggerNode) => triggerNode.parentNode">
      <a-icon slot="suffixIcon" type="caret-down"/>
      <a-select-option value="Validated">
        {{ $t('components.dropdown.validated') }}
      </a-select-option>
      <a-select-option value="Validated with comments">
        {{ $t('components.dropdown.validatedWithComments') }}
      </a-select-option>
      <a-select-option value="Not Validated">
        {{ $t('components.dropdown.notValidated') }}
      </a-select-option>
    </a-select>
    <div class="mt-24 flex justify-space-between">
      <a-button
        class="w200"
        type="primary"
        size="large"
        ghost
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        class="w200"
        type="primary"
        size="large"
        :disabled="!reason"
        @click="onComplete(reason)">
        {{ $t('values.validate') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>

export default {
  name: 'ProtocolValidationModal',
  props: {
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      reason: undefined
    };
  }
};
</script>

<style lang="scss">
</style>
